<template>
  <div id="app" class="app starting" :key="appKey" v-bind="app">
    <UpdateAvailable />
    <router-view />
  </div>
</template>

<script>
  import { mapActions, mapMutations } from "vuex";
  import UpdateAvailable from "@/app/UpdateAvailable.vue";
  import icons from "@/common/icons.js";
  export default {
    metaInfo() {
      if (process.env.NODE_ENV !== "production") {
        return {};
      }
      return {
        script: [
          {
            src: "/js/gtag.js",
            head: true,
            async: false,
          },
          {
            src: "https://www.googletagmanager.com/gtag/js?id=G-7JHWPVYL2H",
            head: true,
            async: true,
          },
          {
            src: "https://www.googletagmanager.com/gtag/js?id=G-PHC4189WEP",
            head: true,
            async: true,
          },
          {
            src: "/js/gAnalytics.js",
            head: true,
            async: false,
          },
          {
            src: "/js/metricool.js",
            body: true,
            async: false,
          },
        ],
        __dangerouslyDisableSanitizers: ["script"],
      };
    },
    components: { UpdateAvailable },
    data() {
      return {
        appKey: 1,
      };
    },
    computed: {
      app: function () {
        const dicApps = {
          website: icons.src("models1a"),
          register: icons.src("website"),
        };

        const appLogo = this.$route.path.includes("/admin") ? dicApps.register : dicApps.website;
        return {
          style: {
            "--app-icon": `url("${appLogo}")`,
          },
        };
      },
    },
    methods: {
      ...mapActions(["startApp"]),
      ...mapMutations(["setCurrentLang"]),
      loaded: function () {
        const app = document.getElementById("app");
        app.classList.remove("starting");
      },
    },
    async beforeMount() {
      this.setCurrentLang();
      await this.startApp();
      this.loaded();
    },
    mounted() {
      this.$on("refreshlang", () => {
        this.appKey++;
      });
    },
  };
</script>

<style lang="scss">
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 12px;
  }
</style>
