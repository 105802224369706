import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
});

instance.interceptors.request.use((config, error) => {
  if (error) {
    return Promise.reject(error);
  }

  const ulang = localStorage.getItem("user-language");
  const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME) || null;
  if (ulang) {
    config.headers.common["user-language"] = ulang;
  }
  if (token) {
    config.headers.common[process.env.VUE_APP_TOKEN_NAME] = token;
  }
  return config;
});

export default { ...instance };
