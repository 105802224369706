export default {
  state: () => ({
    saved: {},
  }),
  mutations: {
    setSuccess: function (state, { saved }) {
      state.saved = saved;
    },
  },
};
