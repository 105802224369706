const routes = [
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
  },
  {
    path: "/denied",
    name: "Denied",
    component: () => import("@/common/Denied.vue"),
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/404.vue"),
  },
  {
    path: "/sesionwrong",
    name: "sesionWrong",
    component: () => import("../common/art/NotSesion.vue"),
  },
];

export default routes;
