export default {
  validateForm: function (formData, config) {
    const _this = this;
    const fmInputs = document.querySelectorAll(`${formData} input, ${formData} select`);
    const fmOptions = document.querySelectorAll(`${formData} input[type="radio"]`);
    [].forEach.call(fmInputs, function (el) {
      if (el.hasAttribute("required")) {
        _this.validateInput(el, config);
      }
    });
    [].forEach.call(fmOptions, function (el) {
      if (el.hasAttribute("required")) {
        _this.validateOption(el, config);
      }
    });
    const dataErrors = document.querySelectorAll(`${formData} .data-error`);
    if (dataErrors.length) {
      return false;
    } else {
      return true;
    }
  },
  validateOption: function (el, config) {
    const _this = this;
    let isSelected = false;
    const myElm = typeof el === "string" ? document.querySelector(el) : typeof el === "object" ? el : el;
    const _inputs =
      typeof el === "string" ? document.querySelectorAll(el + " input") : typeof el === "object" ? el.getElementsByTagName("input") : el;
    [].forEach.call(_inputs, function (_el) {
      if (_el.checked === true) {
        isSelected = true;
      }
    });
    isSelected ? _this.successInput(myElm ? myElm : el) : _this.errorInput(myElm ? myElm : el);
  },
  validEmail: function (email) {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    } else {
      return false;
    }
  },
  validPhone: function (phone) {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  },
  validNumber: function (value) {
    let numbers = /^[0-9]+$/;
    if (value.match(numbers)) {
      return true;
    } else {
      return false;
    }
  },
  validDecimal: function (value) {
    let numbers = /\d*.?\d*/;
    if (value.match(numbers)) {
      return true;
    } else {
      return false;
    }
  },
  validateCode: function (code, length) {
    const regex = new RegExp(`^[a-zA-Z0-9]{${length}}$`);
    if (regex.test(code)) return true;
    return false;
  },
  validateInput: function (el, config = { type: "" }) {
    const _this = this;

    const imageExts = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    const docExts = /(\.(doc|docx|docm|pdf))$/i;

    let typeToValidate = imageExts;
    if (config.type === "file") {
      typeToValidate = docExts;
    }

    if (config.type === "image") {
      typeToValidate = imageExts;
    }
    const _el = el.srcElement ? el.srcElement : typeof el === "object" ? el : el.srcElement;
    const _val = _el.value ? _el.value.trim() : "";
    if (config.type === "code") {
      const length = config.length ? config.length : 6;
      _this.validateCode(_val, length) ? _this.successInput(_el) : _this.errorInput(_el);
      return;
    }
    if (_el.type === "text" || _el.type === "date") {
      _val ? _this.successInput(_el) : _this.errorInput(_el);
    } else if (_el.type === "select-one") {
      _val ? _this.successInput(_el) : _this.errorInput(_el);
    } else if (_el.type === "email") {
      _this.validEmail(_val) ? _this.successInput(_el) : _this.errorInput(_el);
    } else if (_el.type === "tel") {
      _this.validPhone(_val) ? _this.successInput(_el) : _this.errorInput(_el);
    } else if (_el.type === "number") {
      _this.validNumber(_val) ? _this.successInput(_el) : _this.errorInput(_el);
    } else if (_el.type === "file") {
      _el.files.length && typeToValidate.exec(_val) ? _this.successInput(_el) : _this.errorInput(_el);
    }
  },
  errorInput: function (el) {
    let placeholder = el.getAttribute("placeholder");
    el.nextElementSibling ? el.nextElementSibling.remove() : "";
    el.insertAdjacentHTML("afterend", '<div class="tooltip data-error">' + placeholder + "</div>");
    el.classList.add("data-error");
  },
  successInput: function (el) {
    el.nextElementSibling ? el.nextElementSibling.remove() : "";
    el.classList.remove("data-error");
  },
  isEmptyObj: function (obj) {
    if (obj === [] || obj === null || obj === undefined || obj === {} || obj === "[]") {
      return true;
    }
  },
};
