const APP_SESSION = {};

module.exports = APP_SESSION;

APP_SESSION.parseJwt = (nametkn, stored = null) => {
  let token = localStorage.getItem(nametkn);

  if (stored === false) {
    token = nametkn;
  }

  if (token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  }
};

APP_SESSION.requireRoles = (require, { some = false }) => {
  const user = APP_SESSION.parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const userRoles = user && user.roles;
  const isThreshold = (currentRole) => userRoles.includes(currentRole);
  if (userRoles && some) {
    const isRoles = require.some(isThreshold);
    return isRoles;
  } else if (userRoles) {
    const isRoles = require.every(isThreshold);
    return isRoles;
  }
  return false;
};

APP_SESSION.ifAuthenticated = async (to, from, next) => {
  const user = APP_SESSION.parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const isUser = user && user.name;

  if (!to.meta.approvedTo || to.meta.approvedTo === "currentPath") {
    to.meta.approvedTo = to.fullPath;
  }

  if (to.meta.requireSomeRoles && isUser) {
    if (user.roles && APP_SESSION.requireRoles(to.meta.requireSomeRoles, { some: true })) {
      console.log("TO META", to.meta);
      return next(to.meta.approvedTo ? to.meta.approvedTo : "");
    } else {
      return next(to.meta.deniedTo ? to.meta.deniedTo : "/denied");
    }
  }

  if (to.meta.requireRoles && isUser) {
    if (user.roles && APP_SESSION.requireRoles(to.meta.requireRoles, { some: false })) {
      return next(to.meta.approvedTo ? to.meta.approvedTo : "");
    } else {
      return next(to.meta.deniedTo ? to.meta.deniedTo : "/denied");
    }
  }

  if (to.meta.requireRole && isUser) {
    if (user.roles && APP_SESSION.requireRoles([to.meta.requireRole], { some: false })) {
      return next(to.meta.approvedTo ? to.meta.approvedTo : "");
    } else {
      return next(to.meta.deniedTo ? to.meta.deniedTo : "/denied");
    }
  }

  if (isUser) {
    return next(to.meta.approvedTo ? to.meta.approvedTo : "");
  } else {
    return next(to.meta.deniedTo ? to.meta.deniedTo : "/login");
  }
};

APP_SESSION.ifNotAuthenticated = async (to, from, next) => {
  const nav = JSON.parse(localStorage.getItem("meta-nav"));
  const user = APP_SESSION.parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const isUser = user && user.name;
  if (to.meta?.requireQuery && !to.query[to.meta.requireQuery]) {
    return next(to.meta.deniedTo);
  } else if (isUser) {
    return next(nav ? nav.approvedTo : "/");
  } else {
    return next();
  }
};

APP_SESSION.sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

APP_SESSION.SSManager = (Resolve, Reject) => {
  return new Promise(async (resolve, reject) => {
    let [ssmFrame, docFrame] = [null, null];

    ssmFrame = document.getElementById("autologin-ssm");

    if (!ssmFrame) {
      await APP_SESSION.sleep(100);
      return APP_SESSION.SSManager(resolve, reject);
    }

    ssmFrame.contentWindow.postMessage("getProfile", "*");

    APP_SESSION.incomingMessage = ({ data } = {}) => {
      const { token, profile } = data;
      let [dataToken] = [null];

      if (token) {
        dataToken = APP_SESSION.parseJwt(token, false);
      }

      if (token && dataToken && dataToken.app === process.env.VUE_APP_KEY_NAME) {
        localStorage.setItem(process.env.VUE_APP_TOKEN_NAME, token);
      }

      Resolve ? Resolve(true) : resolve(true);
    };

    if (window.addEventListener) {
      window.addEventListener("message", APP_SESSION.incomingMessage, false);
    } else {
      window.attachEvent("onmessage", APP_SESSION.incomingMessage);
    }
  });
};

APP_SESSION.hasRoles = (roles = [], { some = false }) => {
  const user = APP_SESSION.parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const userRoles = user && user.roles;
  const isThreshold = (currentRole) => currentRole && userRoles.includes(currentRole);

  if (typeof roles === "string") {
    roles = [roles];
  }

  if (userRoles && some) {
    return roles.some(isThreshold);
  }

  if (userRoles) {
    return roles.every(isThreshold);
  }

  return false;
};

APP_SESSION.hasUser = () => {
  const user = APP_SESSION.parseJwt(process.env.VUE_APP_TOKEN_NAME);
  const isUser = user && user.name;
  return isUser;
};

APP_SESSION.getSession = function () {
  const [user] = [APP_SESSION.parseJwt(process.env.VUE_APP_TOKEN_NAME)];
  const isAuthenticated = user;
  return isAuthenticated;
};
