import Vue from "vue";
import Vuex from "vuex";
import api from "../api";
import router from "../router";
import { parseJwt } from "../assets/js/session";
import signup from "./signup";
import events from "./events";
import records from "./records";
import user from "./user";
import sessionsApi from "../common/api/sessionsAxios";
import Socket from "@/common/socket/socketClass";
import gallery from "./gallery";
const langs = {
  es: require(`../assets/langs/es.json`),
  en: require(`../assets/langs/en.json`),
};

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    signup,
    events,
    records,
    user,
    gallery,
  },
  state: {
    language: null,
    language_local: null,
    languageObjs: null,
    isSession: null,
    servicesIcons: null,
    userProfile: {},
    isActiveHome: true,
    updates: false,
    photographers: null,
    locations: null,
    rooms: null,
    monitors: null,
    models: null,
    allModels: [],
    screen: { width: window.innerWidth, height: window.innerHeight },
    cities: [],
    socket: null,
    webcastMultimedia: [],
    menus: [
      {
        name: "Nuevos",
        icon: "user_plus",
        to: { name: "NewsRecords", params: { status: "news" } },
        roles: ["coordinator", "superadmin"],
      },
      {
        name: "Entrevista",
        icon: "user_check",
        to: { name: "Interview" },
        roles: ["coordinator", "superadmin"],
      },
      {
        name: "Selección",
        icon: "thumbs_up",
        to: { name: "Selection" },
        roles: ["coordinator", "superadmin"],
      },
      {
        name: "Ingreso",
        icon: "user_document",
        to: { name: "Income" },
        roles: ["coordinator", "superadmin"],
      },
      {
        name: "Contratados",
        icon: "user",
        to: { name: "Hired" },
        roles: ["coordinator", "superadmin"],
      },
    ],
    menuState: "short",
    currentLang: "es",
  },
  mutations: {
    changeMenuState(state, payload) {
      state.menuState = payload;
    },
    setSocket(state, socket) {
      state.socket = socket;
    },
    setCurrentLang(state) {
      const formatLang = (val) => (val ? val.substring(0, 2) : "");
      const availableLangs = ["es", "en"];
      const userLang = formatLang(localStorage.getItem("user-language"));
      const navLang = formatLang(navigator.language);
      const mylang = userLang || navLang;

      state.currentLang = availableLangs.includes(mylang) ? mylang : "es";
      localStorage.setItem("user-language", state.currentLang);
    },
  },
  actions: {
    async startApp() {
      const eloading = document.querySelector("body");

      eloading.classList.add("loading");
      localStorage.removeItem("successRegister");

      await this.dispatch("getLanguage");

      this.dispatch("getLanguageLocal");
      this.dispatch("getServiceIcons");
      this.dispatch("watchScrolling");
      this.dispatch("updateFoundEvent");

      eloading.classList.remove("loading");
    },
    async fetchProfile({ dispatch }) {
      const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
      if (!token) {
        return {};
      }
      const { data } = await sessionsApi.get(`/session/validsession`);
      dispatch("updateUser", data.user);
      return data.user;
    },
    removeUser: async function () {
      localStorage.removeItem(process.env.VUE_APP_TOKEN_NAME);
      this.state.userProfile = null;
      return this.state.userProfile;
    },
    async isApiOnline({ commit }) {
      const _host = `${process.env.VUE_APP_API_HOST}`;
      let $return = [];
      await api
        .get(_host)
        .then(function (response) {
          $return = response;
        })
        .catch(function (error) {
          $return = { data: { success: false, error: error } };
        });
      return $return;
    },
    async getLanguageLocal({ commit }) {
      let userLang = localStorage.getItem("user-language");
      let mylang = userLang ? userLang.substring(0, 2) : navigator.language.substring(0, 2);
      this.state.language_local = await require(`../assets/langs/${mylang}.json`);
    },
    async initilizeSocket({ commit, state }) {
      const socket = new Socket();
      const tokenName = process.env.VUE_APP_TOKEN_NAME;
      const token = localStorage.getItem(tokenName);
      const user = state.userProfile;
      const { role = "", _id = "", area = "", city = "", isSpecialFunctions = false } = user;
      const serverUrl = process.env.VUE_APP_SOCKET;
      const socketInfo = {
        path: "/api/v1/socket.io",
        auth: { [tokenName]: token },
        serverUrl,
        query: `role=${role}&_id=${_id}&area=${area}&city=${city}&isSpecialFunctions=${isSpecialFunctions}`,
      };
      await socket.connect(socketInfo);
      if (socket.getSocket().connected) {
        commit("setSocket", socket.getSocket());
      }
    },
    async getServiceIcons({ commit }) {
      let userLang = localStorage.getItem("user-language");
      const iconsServices = await require(`../assets/servicesIcons.json`);
      this.state.servicesIcons = iconsServices?.icons.map((icon) => ({
        icon: icon.icon,
        title: icon.title[userLang],
      }));
    },
    async getLanguage() {
      const { data: langObjs } = await api.get(`${process.env.VUE_APP_API_HOST}locale`);
      const { data: homeLanguages } = await api.get(`${process.env.VUE_APP_API_HOST}homeLanguages`);

      this.state.languageObjs = langObjs;
      this.state.language = homeLanguages;
    },
    async changeLanguage({ commit, state }, lang) {
      localStorage.setItem("user-language", lang);
      let date = new Date(Date.now() + 86400e3);
      date = date.toUTCString();
      document.cookie = "savedUserLanguage=" + lang + "; expires=" + date + "; domain=form.jotformz.com";
      this.commit("setCurrentLang");
      await this.dispatch("startApp");

      let cquery = router.currentRoute.query;
      router.push({ hash: "" }).catch(() => {});
      router.push({ query: cquery, hash: lang }).catch(() => {});
    },
    async getWebcastMultimedia() {
      const { data } = await this.$axios.get(`webCast/getWebCastMultimedia/?page=1`);
      const { pageContent } = data;
      this.state.webcastMultimedia = pageContent;
    },
    async getAllCities({ state }) {
      try {
        const { data } = await this.$axios.get(`locations/cities`);
        const { error, success, cities } = data;

        if (error) {
          throw new Error(error);
        }

        state.cities = success ? cities : [];
      } catch (error) {
        console.log(error);
      }
    },
    watchScrolling: function ({ state }) {
      window.removeEventListener("scroll", isScrolling, true);
      window.addEventListener("scroll", isScrolling, true);

      function isScrolling() {
        const footerHeight = 185;
        const sectionHome = document.querySelector("#home");
        state.isActiveHome = sectionHome?.classList.contains("section-active");

        if (window.innerHeight + window.scrollY + footerHeight >= document.body.offsetHeight) {
          let elems = document.querySelectorAll(".click-on-bottom");
          [].forEach.call(elems, function (el) {
            if (el) {
              el.click();
            }
          });
        }
      }

      window.addEventListener("resize", function (e) {
        let width = window.innerWidth;
        let height = window.innerHeight;
        state.screen = { width, height };
      });
    },
    updateUser: function (_, userData) {
      this.state.userProfile = userData;
      return this.state.userProfile;
    },
    updateFoundEvent() {
      window.addEventListener("message", (event) => {
        if (event.data.msg === "updatefound") {
          this.state.updates = true;
        }
      });
    },
    isSession: async function ({ commit }) {
      const dataSs = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
      const $return = dataSs ? parseJwt(process.env.VUE_APP_TOKEN_NAME) : false;
      this.state.userProfile = $return;
      return $return;
    },
  },
  getters: {
    iflanguage: (state) => !!state.language,
    localeLang: async (state) => await state.language_local,
    locale: async (state) => await state.language_local,
    newLocale: (state) => langs[state.currentLang],
    getLocale: (state) => state.currentLang,
    dataLang: async (state) => await state.language,
    isSession: async (state) => await state.isSession,
    user: (state) => state.userProfile,
    screen: (state) => state.screen,
    serviceIcons: (state) => state.servicesIcons,
    myMenus: (state) => state.menus.filter(({ roles }) => roles.includes(state.userProfile.role)),
    webcastMultimedias: (state) => state.webcastMultimedia,
    getSocket: async (state) => await state.socket,
  },
});
