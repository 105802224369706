import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import VueMeta from "vue-meta";
import VMdDateRangePicker from "v-md-date-range-picker";
import VueCompositionAPI from "@vue/composition-api";
import Chartkick from "vue-chartkick";
import iquery from "@/plugins/iquery/iquery";
import iconic from "@/plugins/iconic/";
import global from "@/assets/js/global";
import api from "@/api";
import axios from "@/api/axios";
import validate from "@/assets/js/validate";
import Swal from "sweetalert2";
import globalMixin from "@/mixins/global";
import Carousel3d from "vue-carousel-3d";
import "./registerServiceWorker";
import "@/assets/js/prevent";
import "@vueform/slider/themes/default.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "@/assets/styles/fontawesome.css";
import "@/assets/styles/fonts.scss";
import "@/assets/styles/main.scss";
import "@/assets/js/fb.pixel.js";
import AOS from "aos";
import "aos/dist/aos.css";
import VueYoutubeEmbed from "vue-youtube-embed";
Vue.config.productionTip = false;
Vue.prototype.$global = global;
Vue.prototype.$api = api;
Vue.prototype.$axios = axios;
Vue.prototype.$validate = validate;
Vue.prototype.$swal = Swal;
Vue.prototype.$store = store;
Vue.prototype.$host = process.env.VUE_APP_API_HOST;

store.$axios = axios;

Vue.use(VueYoutubeEmbed);
Vue.mixin(globalMixin);
Vue.use(VueMeta);
Vue.use(VMdDateRangePicker);
Vue.use(VueCompositionAPI);
Vue.use(Chartkick);
Vue.use(iconic);
Vue.use(iquery);
Vue.use(Carousel3d);

new Vue({
  router,
  store,
  render: (h) => h(App),
  mounted() {
    document.addEventListener("DOMContentLoaded", function () {
      AOS.init({});
    });
  },
}).$mount("#app");
