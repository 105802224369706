export default {
  namespaced: true,
  state: () => ({}),
  actions: {
    logoutClient({ dispatch }) {
      dispatch("logoutResetData");
      this.dispatch("removeUser");
    },
    logoutResetData() {
      localStorage.clear();
    },
  },
};
