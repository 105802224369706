import api from "@/api";

export default {
  namespaced: true,
  state: () => ({
    dataGallery: null,
    dataVideos: null,
  }),
  mutations: {
    setDataGallery: (state, payload) => {
      state.dataGallery = payload;
    },
    setDataVideos: (state, payload) => {
      state.dataVideos = payload;
    },
  },

  actions: {
    async getGallery({ commit }) {
      try {
        const { data } = await api.get(`${process.env.VUE_APP_API_HOST}gallery`);
        commit("setDataGallery", data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },

    async getGalleryVideos({ commit }) {
      try {
        const { data } = await api.get(`${process.env.VUE_APP_API_HOST}galleryVideos`);
        commit("setDataVideos", data);
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
