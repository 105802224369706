import Vue from "vue";
import VueRouter from "vue-router";
import HomeRoutes from "./homeRouter";
import AdminRoutes from "./adminRouter";
import UserRoutes from "./userRouter";
import ErrorsRoutes from "./errorsRouter";
import guard from "@/common/guard";
import menuList from "../app/menuList";
import store from "../store";

Vue.use(VueRouter);

const setCurrentMenu = (to) => {
  const { query, path } = to;
  const menu = menuList.menu.find((menu) => menu.routes.includes(query.filter) || menu.routes.includes(path));

  if (menu) {
    to.meta.menuActive = menu;
  }
};

const routes = [...HomeRoutes, ...AdminRoutes, ...UserRoutes, ...ErrorsRoutes];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  async scrollBehavior(to) {
    if (to.params.scrollto !== false) {
      if (to.hash) {
        const goToElement = async (hash, retry = 1, delay = 500) => {
          if (retry >= 5) {
            return { x: 0, y: 0 };
          }

          await new Promise((resolve) => setTimeout(resolve, delay));

          const element = document.querySelector(`${hash}`);

          if (element) {
            return { x: 0, y: 0, selector: hash, behavior: "smooth" };
          }

          return await goToElement(hash, retry + 1, delay * (retry + 1));
        };

        return await goToElement(to.hash);
      }
    }
  },
});

router.beforeEach(async (to, from, next) => {
  setCurrentMenu(to);

  const requiresSession = to.matched.some((record) => record.meta.requireAuth);

  if (requiresSession && !store.getters?.user?._id) {
    await store.dispatch("fetchProfile");
  }

  return guard(router, to, from, next);
});

export default router;
