const routes = [
  {
    path: "/admin",
    component: () => import("./../components/admin/Admin.vue"),
    meta: { requireAuth: true, requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
    children: [
      {
        path: "/",
        component: () => import("./../components/admin/AdminHome.vue"),
        meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
      },
      {
        path: "/admin/models",
        component: () => import("./../components/admin/Models.vue"),
        meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
        children: [
          {
            path: "watch",
            component: () => import("../components/web/Watch.vue"),
          },
        ],
      },
      {
        path: "log",
        component: () => import("./../components/admin/AdminLog.vue"),
      },
    ],
  },
  {
    path: "/admin2",
    name: "Records",
    meta: { requireSomeRoles: ["super", "coord"], approvedTo: "/newsrecords", deniedTo: "/denied" },
    component: () => import("../views/Admin.vue"),
    children: [
      {
        name: "NewsRecords",
        path: "/newsrecords",
        component: () => import("../views/RecordsNews.vue"),
        meta: { requireSomeRoles: ["super", "coord"], approvedTo: "/newsrecords/new", deniedTo: "/denied" },
        children: [
          {
            name: "News",
            path: "/newsrecords/:status",
            component: () => import("../components/mainRecords/ViewStatusRecords.vue"),
            meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
          },
          {
            name: "Disapproved",
            path: "/newsrecords/:status",
            component: () => import("../components/mainRecords/ViewStatusRecords.vue"),
            meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
          },
        ],
      },
      {
        name: "Interview",
        path: "/admin2/interview",
        component: () => import("../views/Interview.vue"),
        meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
      },
      {
        name: "Selection",
        path: "/admin2/selection",
        component: () => import("../views/Selection.vue"),
        meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
      },
      {
        name: "Income",
        path: "/admin2/income",
        component: () => import("../views/Income.vue"),
        meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
      },
      {
        name: "Hired",
        path: "/admin2/hired",
        component: () => import("../views/Hired.vue"),
        meta: { requireSomeRoles: ["super", "coord"], deniedTo: "/denied" },
      },
    ],
  },
];

export default routes;
