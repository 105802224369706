import dictionary from "./dictionary";
import api from "../../api";
export default {
  setSrc: (data) => {
    let el = document.querySelector(data.el);
    el.src = data.src;

    let elems = document.querySelectorAll(".product-view-img-wrap .product-view-gallery-img-card");
    [].forEach.call(elems, function (el) {
      el.classList.remove("selected");
    });

    let selelems = document.querySelectorAll(`.product-view-img-wrap .` + data.mrk);
    [].forEach.call(selelems, function (el) {
      el.classList.add("selected");
    });
  },
  getCountriesInfo: async function () {
    let response = await api.get(`${process.env.VUE_APP_API_HOST}countries`);
    const countries = Object.entries(response.data).map(([key, country]) => {
      return country;
    });
    return countries;
  },
  sortSocialMedia: (socialMedia) => {
    const socialMediaNames = ["facebook", "instagram", "tiktok", "twitter", "youtube"];
    
    const footerSocialMedia = socialMedia.filter((social) => social?.indexInFooter >= 0);
    
    let socialMediaToShow = footerSocialMedia.length > 0 ?
      footerSocialMedia.sort((a, b) => a.indexInFooter - b.indexInFooter) :
      socialMedia
        .filter((social) => socialMediaNames.includes(social.network.toLowerCase()))
        .sort((a, b) => socialMediaNames.indexOf(a.network.toLowerCase()) - socialMediaNames.indexOf(b.network.toLowerCase()));
    
    return socialMediaToShow;
  },
  toggleClass: (data) => {
    let el = document.querySelector(data.el);
    let clss = data.class;
    el.classList.toggle(clss);
  },
  //11 de agosto de 2021
  dateLocaleFormatEs: (date, options) => {
    let _date = new Date(date);
    const ulang = "es";
    return _date.toLocaleDateString(ulang, options);
  },
  dateLocaleFormat: (date) => {
    date = new Date(date);
    const ulang = localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      day: "numeric",
      month: "long",
      year: "numeric",
      timeZone: "UTC",
    };
    return date.toLocaleDateString(ulang, options);
  },
  timeAMPM: (time) => {
    const myTime = time.split(":");
    let hours = myTime[0];
    let minutes = myTime[1];
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
  monthName: function (date) {
    date = new Date(date);
    const ulang = localStorage.getItem("user-language").substring(0, 2) || navigator.language.substring(0, 2);
    const options = {
      month: "long",
    };
    return date.toLocaleDateString(ulang, options);
  },
  toInputDate: function (date) {
    if (date && date.includes("T")) {
      date = date.split("T")[0];
    }
    return date;
  },
  toInputDateTime: function (date) {
    if (date && date.includes("Z")) {
      date = date.split("Z")[0];
    }
    return date;
  },
  currentYMD: function () {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1; //months from 1-12
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    return year + "-" + month + "-" + day;
  },
  yearsDiff: (d1, d2) => {
    let yearsDiff = new Date(new Date(d2) - new Date(d1)).getFullYear() - 1970;
    return yearsDiff;
  },
  getMainImg: (data) => {
    var found = data.find(function (element) {
      if (element.isMain === true) return element;
    });
    if (found) {
      return found.link;
    }
  },
  getMainlang: (data, lang) => {
    var found = data.find(function (element) {
      if (element.languageId === lang) return element;
    });
    if (found) {
      return found;
    }
  },
  validEmail: (email) => {
    if (/^[^@ ]+@[^@ ]+\.[^@ \.]{2,}$/.test(email)) {
      return true;
    } else {
      return false;
    }
  },
  validPhone: (phone) => {
    if (/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/.test(phone)) {
      return true;
    } else {
      return false;
    }
  },

  getShort: (text, limit) => {
    if (typeof limit === "number") {
      let ellipsis = text.length > limit ? "..." : "";
      text = text.replace(/(<([^>]+)>)/gi, " ");
      return typeof text !== "string" ? text : text.substring(0, limit) + ellipsis;
    } else {
      return text;
    }
  },

  removeHTMLEntities: function (textoHTML) {
    const entidadesHTML = {
      "&lt;": "<",
      "&gt;": ">",
      "&amp;": "&",
      "&quot;": '"',
      "&apos;": "'",
      "&nbsp;": " ",
    };

    let textoLimpio = textoHTML;
    for (const entidad in entidadesHTML) {
      if (entidadesHTML.hasOwnProperty(entidad)) {
        const expresionRegular = new RegExp(entidad, "g");
        textoLimpio = textoLimpio.replace(expresionRegular, entidadesHTML[entidad]);
      }
    }
    return textoLimpio;
  },

  linkIsActive: ($route, input) => {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some((path) => {
      return $route.fullPath.indexOf(path) === 0; // current path starts with this path string
    });
  },
  linkInclude: ($route, input) => {
    if ($route && input) {
      if ($route.fullPath.includes(input)) {
        return true;
      }
    }
    return false;
  },
  scrollToEl: (data) => {
    let el = document.querySelector(data.el);
    let y = el.offsetTop + -data.topgap;
    window.scroll({ top: y, behavior: data.behavior });
  },
  fastID: () => {
    function chr4() {
      return Math.random().toString(16).slice(-4);
    }
    return chr4() + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + "-" + chr4() + chr4() + chr4();
  },
  hasSome: function (data1, data2) {
    if (typeof data1 === "object" && typeof data2 === "object") {
      return data1.some((some) => data2.includes(some));
    }
  },
  dictionary: {
    ...dictionary.dictionary,
  },
  loading: (elm) => {
    const mylmnt = document.querySelector(elm);
    if (mylmnt) mylmnt.classList.add("loading");
  },
  unloading: (elm) => {
    const mylmnt = document.querySelector(elm);
    if (mylmnt) mylmnt.classList.remove("loading");
  },
  objectIndex: (arr, key, val) => {
    return arr.findIndex((obj) => obj[key] === val);
  },
  setAttribute: (element, attribute, value) => {
    const selelems = document.querySelectorAll(element);
    [].forEach.call(selelems, function (el) {
      el.setAttribute(attribute, value);
    });
  },
  replaceAll: function (string, search, replace) {
    return string.split(search).join(replace);
  },
  getApp: function (appKey) {
    const apps = this.dictionary.apps_icons;
    const app = apps.filter((a) => a.key === appKey);
    return app[0];
  },
  getHost: function (url) {
    const theurl = new URL(url);
    return theurl.origin;
  },
  getDayName: (myDate) => {
    let date = new Date(myDate);
    let day = date.toLocaleString("en-us", { weekday: "long" });
    return day;
  },
  normalizeString: function (data = "") {
    let strToNormalize = data;
    strToNormalize = strToNormalize.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    strToNormalize = strToNormalize.toLocaleLowerCase();
    strToNormalize = strToNormalize.split(" ").join("");
    return strToNormalize;
  },
  newUrl: function (link) {
    if (!link) return;
    let pattern = /\.com(.*)/;
    let newLink = link.match(pattern);
    let afterCom = newLink[1];
    let cloudFront = "https://cdn.models1a.com";
    return cloudFront.concat(afterCom);
  },
};
