module.exports = {
  autologin: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/autologin.svg" />`,
  website: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/website.svg" />`,
  administration: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/administration.svg" />`,
  reservations: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/reservations.svg" />`,
  statistics: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/statistics.svg" />`,
  pays: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/pays.svg" />`,
  ipsecure: `<img src="https://new-web-site-bucket.s3.amazonaws.com/apps/icons/ipsecure.svg" />`,
  home: `<svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.10595C0 6.23333 0.402396 5.40946 1.09063 4.87299L6.62702 0.557446C6.89156 0.351244 7.26292 0.353115 7.52536 0.561971L12.9425 4.87302C13.6175 5.41016 14.0108 6.22572 14.0108 7.08833V14.5797C14.0108 15.141 13.5557 15.596 12.9944 15.596H9.54619C8.98489 15.596 8.52986 15.141 8.52986 14.5797V11.1314C8.52986 10.6904 8.17235 10.3329 7.73132 10.3329H6.27943C5.83841 10.3329 5.48089 10.6904 5.48089 11.1314V14.5797C5.48089 15.141 5.02587 15.596 4.46457 15.596H1.01632C0.455024 15.596 0 15.141 0 14.5797V7.10595Z" fill="#343434"/>
    </svg>`,
  src: (key) => `https://myspace1a.s3.amazonaws.com/apps/icons/${key}.svg`,
  supportIcon: `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 17 21" fill="none">
      <g>
        <g id="Capa_1">
          <path class="cls-1" d="M10.1,16.9c.3.3.6.5,1,.7,1.5.6,3,1.3,4.5,2,.2,0,.3.2.4.3.2.3.1.6,0,.8-.2.2-.5.3-.8.2-.5-.2-1.1-.5-1.6-.7-.9-.4-1.9-.8-2.8-1.2-.1,0-.2,0-.3,0-.4.5-.9,1.1-1.3,1.6-.4.4-.8.4-1.2,0-.4-.5-.9-1.1-1.3-1.6-.1-.1-.2-.1-.3,0-1.4.6-2.8,1.2-4.2,1.8,0,0-.2,0-.3.1-.4.1-.8,0-.9-.4-.1-.3,0-.7.4-.9.6-.3,1.1-.5,1.7-.7,1-.5,2.1-.9,3.1-1.4.3-.1.5-.4.7-.5.1,0,0-.1,0-.1-.8-.4-1.5-1-2.1-1.7-.6-.8-1.1-1.6-1.3-2.6,0-.2-.1-.2-.3-.2-.3,0-.7,0-1,0-.8-.2-1.3-.8-1.3-1.6,0-.7,0-1.4,0-2.1,0-1.2,0-2.4.5-3.5C2.3,2.3,4.4.5,7.4,0c4-.6,7.7,1.9,8.6,5.8.1.5.2.9.2,1.4,0,1.1.1,2.2,0,3.3,0,.9-.6,1.6-1.4,1.7-.3,0-.6,0-.9,0-.2,0-.3,0-.4.3-.5,1.6-1.4,2.9-2.8,3.9-.2.1-.5.3-.7.4ZM3.2,6.7s0,0,0,0c.1-.4,1.3-4,5.3-4s5.1,3.5,5.2,3.9,0,0,0,0,0,0,0,0h1s0,0,0,0,0,0,0,0c-.6-3.3-2.7-4.5-4.3-5s-2.5-.4-3.7,0c-3.7,1-4.4,4.6-4.5,5.1s0,0,0,0,0,0,0,0h.9s0,0,0,0ZM12.6,7.5c0,0,0-.1-.2-.1h-1.2c-.4,0-.8-.2-1-.5s-.2-.4-.4-.4-.4.2-.6.5-.6.4-.9.4h-3.7c0,0-.1,0-.2.1-.4,5.3,1.6,7.6,3.9,7.7h.2c3.9-.2,4.2-5.1,4.1-7.8Z"/>
        </g>
      </g>
    </svg>
  ` 
};
