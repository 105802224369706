import { io } from "socket.io-client";

class Socket {
  constructor() {
    this.socket = null;
  }

  /**
   * @param {Object} socketInit
   */
  connect(socketInit) {
    const { serverUrl, auth, path, query, ...restOptions } = socketInit;

    if (!this.socket) {
      this.socket = io(serverUrl, {
        path,
        auth,
        query,
        ...restOptions,
        transports: ["websocket"],
      });
      return new Promise((resolve, reject) => {
        this.socket.on("connect", () => {
          console.log("Conexión establecida con el servidor de sockets.");
          this.socket.emit("isAvailable");
         
          resolve();
        });

        this.socket.on("error", (error) => {
          console.error(`Error al conectar con el servidor de sockets: ${error}`);
          reject(error);
        });
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  /**
   * @param {String} eventName
   * @param {Function} callback
   */
  on(eventName, callback) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }

  /**
   * @param {String} eventName
   * @param {Function} callback
   */
  off(eventName, callback) {
    if (this.socket) {
      this.socket.off(eventName, callback);
    }
  }

  /**
   *
   * @returns this.socket
   */
  getSocket() {
    return this.socket;
  }
}

export default Socket;
