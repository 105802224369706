export default {
  brand: { name: "My Space", icon: "myspace" },
  menu: [
    {
      id: 1,
      name: "Estadisticas",
      icon: "pie",
      to: "/admin/models/?filter=all&mode=graphics",
      routes: ["all", "/admin"],
    },
    {
      id: 2,
      name: "Aspirantes",
      icon: "applicants",
      to: "/admin/models/?filter=new#app",
      routes: ["new", "approved", "noassistence", "pending", "disapproved"],
    },
    {
      id: 3,
      name: "Seleccionadas",
      icon: "selected",
      to: "/admin/models/?filter=selected#app",
      routes: ["selected", "noselected"],
    },
    {
      id: 4,
      name: "Admitidas",
      icon: "admitted",
      to: "/admin/models/?filter=admitted#app",
      routes: ["admitted", "waiting", "noadmitted"],
    },
    {
      id: 5,
      name: "Contratadas",
      icon: "hired",
      to: "/admin/models/?filter=hired#app",
      routes: ["hired", "deserter", "waiver", "endContract"],
    },
  ],
};
