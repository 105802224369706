import { methods } from "./methods";
import { components } from "./components";
export default {
  methods: methods,
  components: components,
  computed: {
    $user() {
      return this.$store.state.userProfile;
    },
    $locale() {
      return this.$store.state.language;
    },
    $locale_local() {
      return this.$store.state.language_local;
    },
    $langObjs() {
      return this.$store.state.languageObjs;
    },
    $updates() {
      return this.$store.state.updates;
    },
    $screen() {
      return this.$store.state.screen;
    },
  },
};
