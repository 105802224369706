export default {
  state: () => ({
    iframe: null,
    host: null,
  }),
  mutations: {
    emitToCloud: function (state, data) {
      if (state.iframe) {
        state.iframe.contentWindow.postMessage(data, state.host);
      }
    },
    setIframe: function (state, { iframe, host }) {
      state.iframe = iframe;
      state.host = host;
    },
  },
};
