export default {
  namespaced: true,
  state: () => ({
    recordDetailId: null,
    recordsInFilter: [],
    isLoadingRecords: false,
    hasMore: false,
    documentsInFilter: 0,
    page: 1,
    imagesToShow: {
      initialSlide: 0,
      images: [],
      recordId: "",
    },
  }),

  actions: {
    async getDocumentsInFilter(_, query) {
      try {
        const { data } = await this.$axios.get(`records/numstatus?${query}`);
        const { error, success, documentsStatus } = data;

        if (error) {
          throw new Error(error);
        }

        return success ? documentsStatus : 0;
      } catch (error) {
        console.log(error);
      }
    },
    async searchDocumentsInFilter({ state, dispatch }, query) {
      state.documentsInFilter = await dispatch("getDocumentsInFilter", query);
    },
    async getRecords({ state }, query = "") {
      try {
        state.isLoadingRecords = true;

        const { data } = await this.$axios.get(`records?${query}`);
        const { error, success, records, hasMore } = data;

        if (error) {
          throw new Error(error);
        }

        state.hasMore = hasMore;
        return success ? records : [];
      } catch (error) {
        console.log(error);
      } finally {
        state.isLoadingRecords = false;
      }
    },
    async executeGetRecords({ state, dispatch }, query) {
      if (state.isLoadingRecords) {
        return;
      }

      dispatch("resetValues");

      const paginationValues = `page=${state.page}&limit=10`;

      query += paginationValues;
      state.recordsInFilter = await dispatch("getRecords", query);
    },
    async getMoreRecords({ state, dispatch }, query) {
      if (!state.hasMore || state.isLoadingRecords) {
        return;
      }
      state.page += 1;
      const paginationValues = `page=${state.page}&limit=10`;

      query += paginationValues;
      const newDates = await dispatch("getRecords", query);
      state.recordsInFilter.push(...newDates);
    },
    resetValues({ state }) {
      state.recordsInFilter = [];
      state.page = 1;
      state.isLoadingRecords = false;
      state.hasMore = false;
    },
  },
};
