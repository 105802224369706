import { hasRoles, hasUser, hasPolicy } from "./session.js";

export default function (router, to, from, next) {
  return _next(router, to, from, next);
}

const _next = function (router, to, from, next) {
  if (!to.meta) return next();

  let redirect = false;

  if (to.meta.requireAuth && !hasUser()) {
    router.push({ path: to.meta.deniedTo || "/denied" });
    redirect = true;
  }

  if (to.meta.requireNotAuth && hasUser()) {
    router.push({ path: to.meta.deniedTo || "/denied" });
    redirect = true;
  }

  if (to.meta.requireSomeRoles && !hasRoles(to.meta.requireSomeRoles, { some: true })) {
    if (hasPolicy(to.meta.requirePolicy, { some: true })) {
      return next();
    } else {
      router.push({ path: to.meta.deniedTo || "/denied" });
      redirect = true;
    }
  }

  if (to.meta.requireRoles && !hasRoles(to.meta.requireRoles, { some: false })) {
    router.push({ path: to.meta.deniedTo || "/denied" });
    redirect = true;
  }

  if (to.meta.requireRole && !hasRoles(to.meta.requireRole, { some: false })) {
    router.push({ path: to.meta.deniedTo || "/denied" });
    redirect = true;
  }

  if (!redirect && to.meta.approvedTo && to.path !== to.meta.approvedTo) {
    router.push({ path: to.meta.approvedTo });
  }

  return next();
};
