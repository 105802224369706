<template>
  <i :ref="`iconic-${id}`" :class="`iconic ${mapClass()}`" :style="`${setSize()}`" v-if="name || svgCode"></i>
</template>

<script>
import icons from "./icons";
export default {
  name: "iconic",
  props: {
    name: String,
    color: { type: String, default: "currentColor" },
    options: { type: Object },
    size: String,
    svgCode: String,
  },
  data: function () {
    return {
      icons: icons,
      id: "",
    };
  },
  computed: {
    assignDinamicId() {
      const array = new Uint32Array(2);
      crypto.getRandomValues(array);
      return `${array[0]}${array[1]}`;
    },
  },
  mounted() {
    this.renderIcon(this.name);
  },
  beforeMount() {
    this.id = this.assignDinamicId;
  },
  methods: {
    setSize: function () {
      if (this.size) {
        return `font-size:${this.size}`;
      }
    },
    mapClass: function () {
      let $class = "";
      if (this.options?.original) {
        $class += "original";
      }
      return $class;
    },
    renderIcon(name) {
      this.$refs[`iconic-${this.id}`].innerHTML = this.myIcon(name);
    },
    evaluate: function (svg) {
      if (!svg) return;
      let elsvg = document.createElement("div");
      elsvg.innerHTML = svg;
      const nodes = elsvg.querySelectorAll("*");
      let color = this.color || "currentColor";
      nodes.forEach((node) => {
        if (node.hasAttribute("fill") && node.getAttribute("fill") !== "none") {
          node.setAttribute("fill", this.color);
        }
        if (node.tagName === "path" && !node.hasAttribute("fill")) {
          node.setAttribute("fill", color);
        }
        if (node.hasAttribute("stroke")) {
          node.setAttribute("stroke", this.color);
        }
      });
      const html = Array.prototype.reduce.call(
        nodes,
        function (html, node, count) {
          count++;
          if (count === 1) {
            html = node.outerHTML || node.nodeValue;
          }
          return html;
        },
        ""
      );
      return html;
    },
    myIcon: function (name) {
      const icon = icons(name) || this.svgCode;
      const theicon = this.evaluate(icon);
      return theicon ?? "?";
    },
  },
};
</script>

<style lang="scss">
.iconic {
  &:not(.original) {
    font-style: normal;
    line-height: 1em;
    svg {
      width: 1em;
      height: 1em;
      vertical-align: middle;
    }
  }
}
</style>
