/*
@Created By
@Luis Carlos Hurtado
*/
export default {
  install(Vue, options) {
    Vue.mixin({
      methods: {
        routerPush: function (query, params) {
          const myPath = this.$route.path;
          const myQuery = Object.assign({ path: myPath }, query, params);
          this.$router.push(myQuery).catch(() => {});
        },
        addQuery: function (myQuery) {
          let currentQuery = this.$route.query;
          let newQuery = { query: { ...currentQuery, ...myQuery } };
          this.routerPush(newQuery);
        },
        removeQuery: function (myQuery) {
          let currentQuery = this.$route.query;
          currentQuery[myQuery] ? delete currentQuery[myQuery] : "";
          let newQuery = { query: { ...currentQuery } };
          this.routerPush(newQuery);
        },
      },
    });
  },
};
