<template>
  <div class="update-available" @click="refresApp" v-if="$langObjs && $updates">
    <div class="update-available-content" v-bind="cacheClean()">
      <div class="update-available-card">
        <h1 class="update-available-logo">Models 1A</h1>
        <div class="update-available-title">
          <h1>{{ updateFound.title }}</h1>
        </div>
        <div class="update-available-message">
          <p>{{ updateFound.description }}</p>
        </div>
        <div class="update-available-options">
          <a @click="refresApp">{{ updateFound.button }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    elements: function (elm) {
      const elms = {
        body: document.querySelector("body"),
      };
      return elms[elm] || elms["custom"];
    },
    refresApp: function () {
      this.elements("body").classList.add("loading");
      this.cacheClean();
      top.location.reload();
    },
    cacheClean: function () {
      this.elements("body").classList.add("onmodal");
      window?.caches?.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    },
  },
  computed: {
    updateFound() {
      return this.$langObjs["global-update"];
    },
  },
};
</script>

<style lang="scss" scoped>
.update-available {
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: hsla(0, 0%, 98%, 0.5);
  backdrop-filter: saturate(1.8) blur(20px);
  user-select: none;
  &-content {
    @include Flex(inherit, center, center);
    height: 100%;
    overflow-y: auto;
    padding: $mpadding;
  }
  &-card {
    background: rgb(255, 255, 255);
    color: black;
    width: 100%;
    max-width: 320px;
    border-radius: $mpadding;
    text-align: center;
    overflow: hidden;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  }
  &-logo {
    pointer-events: none;
    padding: $mpadding * 2 $mpadding * 2 $mpadding $mpadding * 2;
    font-family: $third_font;
    color: $primary_color;
    font-size: 25px;
  }
  &-title {
    padding: 0 $mpadding * 2 $mpadding $mpadding * 2;
  }
  &-message {
    padding: 0 $mpadding * 2 $mpadding $mpadding * 2;
    font-size: 14px;
  }
  &-options {
    a {
      display: block;
      padding: $mpadding;
      border-top: solid 1px $alto;
      cursor: pointer;
      font-weight: bold;
      &:active {
        background: $primary_color;
        color: white;
      }
    }
  }
}
</style>
