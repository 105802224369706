const routes = [
  {
    path: "/user",
    component: () => import("./../components/user/user.vue"),
    meta: { requireNotAuth: true, approvedTo: "/login" },
    children: [
      {
        path: "/user/profile",
        component: () => import("./../components/user/userLogin.vue"),
        meta: { requireNotAuth: true, approvedTo: "/login" },
      },
    ],
  },
  {
    path: "/login",
    component: () => import("./../components/user/userLogin.vue"),
    meta: { requireNotAuth: true, approvedTo: "/login", deniedTo: "/" },
    children: [
      {
        path: "/login",
        component: () => import("./../components/user/userLoginForm.vue"),
      },
    ],
  },
  {
    path: "/update_pass",
    component: () => import("../components/user/userUpdatePass.vue"),
  },
  {
    path: "/verifyCode",
    component: () => import("../components/user/verifyCodePass.vue"),
  },
  {
    path: "/auth",
    component: () => import("@/common/Auth.vue"),
  },
];

export default routes;
