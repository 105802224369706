if (process.env.VUE_APP_MODE === "prod") {
  !(function (f, b, e, v, n, t, s) {
    if (f.fbq) return;
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = "2.0";
    n.queue = [];
    t = b.createElement(e);
    t.async = !0;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
  fbq("init", "835580330762582");
  fbq("track", "PageView");

  const noscript = document.createElement("noscript");
  const pixel = document.createElement("img");
  pixel.src = "https://www.facebook.com/tr?id=835580330762582&ev=PageView&noscript=1";
  pixel.width = 1;
  pixel.height = 1;
  pixel.style.display = "none";
  noscript.appendChild(pixel);
  document.body.appendChild(noscript);
}
