import axios from "axios";

axios.interceptors.request.use(function (config) {
  const ulang = localStorage.getItem("user-language");
  const token = localStorage.getItem(process.env.VUE_APP_TOKEN_NAME);
  const sid = localStorage.getItem("SID");
  if (ulang) {
    config.headers.common["user-language"] = ulang;
  }
  if (token) {
    config.headers.common[process.env.VUE_APP_TOKEN_NAME] = token;
  }
  if (sid) {
    config.headers.common["sid"] = sid;
  }
  return config;
});

export default {
  get: (url, request) => axios.get(url, request),
  post: (url, request) => axios.post(url, request),
  patch: (url, request) => axios.patch(url, request),
  delete: (url, request) => axios.delete(url, request),
};
