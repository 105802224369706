const apps = require("@/common/apps.js");

const dictionary = {
  ...apps,
  cities: {
    Medellín: "medellin",
    Bogotá: "bogota",
    Cúcuta: "cucuta",
    Pereira: "pereira",
  },
  months: [
    { es: "Enero", en: "January", days: 31 },
    { es: "Febrero", en: "February", days: 28 },
    { es: "Marzo", en: "March", days: 31 },
    { es: "Abril", en: "April", days: 30 },
    { es: "Mayo", en: "May", days: 31 },
    { es: "Junio", en: "June", days: 30 },
    { es: "Julio", en: "July", days: 31 },
    { es: "Agosto", en: "August", days: 31 },
    { es: "Septiembre", en: "September", days: 30 },
    { es: "Octubre", en: "October", days: 31 },
    { es: "Noviembre", en: "November", days: 30 },
    { es: "Diciembre", en: "December", days: 31 },
  ],
  networksInfo: {
    instagram: {
      baseUrl: "https://www.instagram.com/",
      regex: /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/(?:@)?([a-zA-Z0-9._]+)/,
    },
    facebook: {
      baseUrl: "https://www.facebook.com/",
      regex: /^(?:https?:\/\/)?(?:www\.)?facebook\.com\/(?:profile\.php\?id=)?([a-zA-Z0-9.]+)/,
    },
    tiktok: {
      baseUrl: "https://www.tiktok.com/@",
      regex: /^(?:https?:\/\/)?(?:www\.)?tiktok\.com\/@([a-zA-Z0-9._]+)/,
    },
    onlyfans: {
      baseUrl: "https://www.onlyfans.com/",
      regex: /^(?:https?:\/\/)?(?:www\.)?onlyfans\.com\/([a-zA-Z0-9._]+)/,
    },
  },
  roles: {
    coordinator: "coord",
    superadmin: "super",
    model: "model",
    monitor: "monitor",
  },
  apps: {
    autologin: "AutoLogin",
    administration: "Administración",
    reservations: "Reservas",
    statistics: "Estadísticas",
    website: "Sitio web",
    pays: "Recibos de pago",
  },
  logActivity: {
    new: "se registro en Models1a",
    approved: "aprobó",
    disapproved: "desaprobó",
    selected: "seleccionó",
    noselected: "envió a no seleccionadas",
    admitted: "admitió",
    waiting: "puso en espera a",
    noadmitted: "no admitió ",
    hired: "contrató a",
    deserter: "puso en desertó a ",
    pending: "puso en pendiente a ",
    waiver: "puso en renuncias a",
    endContract: "puso en cancelación de contrato a",
  },
  documentType: {
    idcard: "Cédula de ciudadanía",
    idforeign: "Cédula de extranjería",
    passport: "Pasaporte",
    cardForeign: "Tarjeta de extranjería",
    pep: "PEP",
    ppt: "PPT",
  },
  waiverReason: {
    nr: "No Veo Resultados",
    de: "Disciplina y Exigencia del Estudio",
    mi: "Modelo Independiente",
    os: "Otro Estudio",
    tp: "Temas Personales",
    sur: "Cirugías",
  },
  endContractReason: {
    lp: "Baja Producción",
    nd: "Indisciplina",
    lc: "Poco Compromiso",
    siu: "Compartir Información con Usuarios",
    ci: "Comportamiento Irregular",
    ml: "Ausencia Laboral",
  },
  workShifts: {
    morning: { name: "Mañana", schedule: "06:00 AM - 02:00 PM" },
    late: { name: "Tarde", schedule: "02:00 PM - 10:00 PM" },
    night: { name: "Noche", schedule: "10:00 PM - 06:00 AM" },
  },
};

module.exports = { dictionary };
